@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,500;1,500&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #111;
    min-height: 100vh;
}

#root, #App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    gap: 1rem;
}

canvas {
    display: block;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: inset 1px 0 0px rgba(0, 0, 0, 0.5);
}

#shapes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

span {
    color: #fff;
    text-align: center;
    padding-top: 1rem;
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    width: -webkit-fill-available;
    width: 100%;
    display: block;
}

.italic {
    display: inline;
    font-style: italic;
}

.circle {
    position: fixed;
    width: 400;
    height: 400px;
    width: 400px;
    z-index: -1;
    border-radius: 50%;
    filter: blur(100px);
}

.circle.circle-bottom {
    bottom: -10%;
    right: -10%;
    background: #d74cc4;
}

.circle.circle-top {
    top: -10%;
    left: -10%;
    background: #4cb4d7;
}

.logo {
    mix-blend-mode: screen;
    backdrop-filter: blur(20px);
    border-radius: 20px;
    box-shadow:  0 0 60px #bebebe2e;
}

.canvas-label {
    min-height: 32px;
}

.canvas-shapes {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    transform: scale(0.7);
}

.display-none {
    display: none;
}

.canvas-container {
    position: relative;
}

.canvas-help {
    position: absolute;
    width: 100%;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}